import React from 'react'
import {useIntl} from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {HeroSoftwares} from '../components/hero/hero.softwares'
import {StartUpMentoring} from '../components/softwares/startup-mentoring'
import {SoftwareClientReviews} from '../components/softwares/client-reviews'
import {Softwares} from '../components/softwares/softwares'
import {TheyTrustUs} from '../components/softwares/they-trust-us'

const TestPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title="JQ - Software"/>
      <HeroSoftwares intl={intl}/>
      <Softwares intl={intl}/>
      <SoftwareClientReviews intl={intl}/>
      <TheyTrustUs intl={intl}/>
      <StartUpMentoring intl={intl}/>
    </Layout>
  )
}

export default TestPage
